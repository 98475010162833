<script>
    import BodyHelperComp from "./Utils/BodyHelperComp.svelte"
    import {link} from "svelte-spa-router"
    // import arcImg from "../../public/Images/archetecia.jpg"
</script>

<BodyHelperComp 
    description={"In collaboration with my friend Adarsh Singh it was possibele to make this website for a company he was freelancing to, Blusteak Media is a creative digital marketing agency located at Kottayam, Kerala. Our goal is to revolutionize marketing using the power of the internet."}
    websiteName={"Blue Streak"}
    answer={["web design","vanila js"]}
    image={"https://i.postimg.cc/Zq0HvVdd/amazon-Mac.png"}
    link={"https://blusteak.com/"}
/>
<BodyHelperComp 
    description={"This website was designed and developed all by myself, Archetecia is a immaginary firm/company which deals with home interior and designes."}
    websiteName={"Archetecia"}
    answer={["web design","vanila js"]}
    image={"https://i.postimg.cc/wTjr2TXL/archetecia-Mac.png'"}
    link={"https://archetecis-react-js.pages.dev/"}
/>
<BodyHelperComp 
    description={"In collaboration with my friend Adarsh Singh it was possibele to make this website for a company he was freelancing to, Dwellingo is a Real Estate Developer providing design-centered, serviced-home experiences to working professionals, students & seniors."}
    websiteName={"Dwellingo"}
    answer={["web design","vanila js"]}
    image={"https://i.postimg.cc/KzNbWG0L/dwellingo-Mac.png"}
    link={"https://www.dwellingo.in/"}
/>
<BodyHelperComp 
    description={"This website was designed and developed all by myself, Cosy Basket is a immaginary Stylist firm which deals with selling desinary cloths"}
    websiteName={"Cosy Basket"}
    answer={["web design","vanila js"]}
    image={"https://i.postimg.cc/dV5wntSy/cosyMac.png"}
    link={"https://cosy-basket-e-com.pages.dev/"}
/>
<BodyHelperComp 
    description={"Developed using react, just here to demonstrate deep api integratiion and object destructuring skills"}
    websiteName={"Covid tracker"}
    answer={["web design","vanila js"]}
    image={"https://i.postimg.cc/g2gLRpVM/covid-Tracker-Mac.png"}
    link={"https://covid-tracker-react-js.pages.dev/"}
/>

<style> 

</style>