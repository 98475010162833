<script>
    import Navbar from "./Navbar.svelte"
    import Hero from "./Hero.svelte"
    import Body from "./Body.svelte"
</script>

<main>
	<Navbar/>
	<Hero/>
	<Body/>
</main>

